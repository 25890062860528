<template>
  <div>
    <md-card class="card-altice">
      <md-card-header>
        <div class="md-title">Configurações de Notificações do Portal de Projetos B2B</div>
        <!-- <div class="md-subhead">With hover and ripple effects</div> -->
      </md-card-header>

      <md-card-content>
        <b-container>
          <b-row>
            <b-col>
              <md-switch v-model="form.sms" @change="changeNotification()" class="md-secondary">Receber notificação por SMS</md-switch>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <md-switch v-model="form.email" @change="changeNotification()" class="md-secondary">Receber notificação por Email</md-switch>
            </b-col>
          </b-row>
        </b-container>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Profile from '@/api/services/Profile.js'

export default {
  name: 'Settings',
  data () {
    return {
      form: {
        sms: false,
        email: false
      }
    }
  },
  methods: {
    changeNotification () {
      this.$loading(true)
      Profile.putMe(this.form).then(data => {
        this.form.sms = data.data.sms_notification
        this.form.email = data.data.email_notification
        this.$loading(false)
      }).catch(() => {
        this.$loading(false)
      })
    }
  },
  created () {
    this.$loading(true)
    Profile.getMe().then(data => {
      this.form.sms = data.data.sms_notification
      this.form.email = data.data.email_notification
      this.$loading(false)
    }).catch(() => {
      this.$loading(false)
    })
  }
}
</script>

<style lang="scss">
.card-altice {
  margin: auto auto;
  max-width: 1000px;
  // min-height: 45vh;
  padding-left: 15px;
}
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 160px !important;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
