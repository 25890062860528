import * as Api from '@/api'

const GET_ME = '/profiles/me/'

export default {
  getMe () {
    return Api.http(true).get(GET_ME)
  },
  putMe (data) {
    return Api.http(true).put(GET_ME, data)
  }
}
